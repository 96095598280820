<script setup lang="ts">
import { scrollToTop } from '~/utils/helper'

const el = document.getElementById('scrollEl')
// const { x, y } = useScroll(el)
const { x, y } = useWindowScroll()
const isShow = computed(() => {
  return y.value > 500
})
const goBackTop = () => scrollToTop(el)
</script>

<template>
  <!-- 回到顶部 -->
  <button v-if="isShow" fixed bottom-5 right-2 mt-15 @click="goBackTop">
    <BaseIcon name="top" size="2.8" />
  </button>
</template>
